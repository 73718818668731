// Import our CSS
import Vue from 'vue';
import '../scss/main.scss';
import 'intersection-observer';
// eslint-disable-next-line no-unused-vars
import Collapse from './components/bootstrap/collapse';
import Menu from './components/menu/menu';
import GlobalAnimations from './components/animations/global';
import FormValidation from './components/validation/form';
import Home from './pages/home';
import ThankYou from './pages/thankyou';
import './components';
import './tests/modernizr.custom';

function requireAll(r) {
  r.keys()
    .forEach(r);
}

requireAll(require.context('../../web/img/icons', true, /\.svg$/));

// App main
/* eslint-disable no-new */
new Vue({
  el: '#page-container',
  delimiters: ['${', '}'],
  components: {},
  data: {},
  methods: {
    loadPage() {
      const main = document.querySelector('main');
      if (!main || !main.id) {
        return;
      }

      switch (main.id) {
        case 'home': {
          new Home();
          break;
        }
        case 'thank-you': {
          new ThankYou();
          break;
        }
        default: {
          break;
        }
      }
    },
  },
  mounted() {
    this.loadPage();

    // Create menu
    if (document.getElementById('menu')) {
      new Menu('#menu');
    }
    // Validate Forms
    [...document.querySelectorAll('.js-validate-form')].forEach((form) => {
      new FormValidation(form);
    });

    GlobalAnimations();
  },
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
