import gsap from 'gsap';
import SplitText from '../../utils/split_text';

function titleAnimation() {
  [...document.querySelectorAll('.js-split-text--char > *')].forEach((text) => {
    const { chars } = new SplitText(text).splitWordsThanChars();
    gsap.from(chars, {
      duration: 0.5,
      ease: 'Power2.easeOut',
      y: '100%',
      scrollTrigger: text,
      stagger: {
        amount: 0.5,
      },
    });
  });
}

function blockTextAnimation() {
  [...document.querySelectorAll('.js-split-text--block > *')].forEach((block) => {
    gsap.from(block, {
      duration: 0.75,
      ease: 'Power2.easeOut',
      y: '50%',
      rotation: 4,
      opacity: 0,
      scrollTrigger: block,
      stagger: {
        amount: 0.5,
      },
    });
  });
}

function selfAnimation() {
  [...document.querySelectorAll('.js-animate-self')].forEach((el) => {
    gsap.from(el, {
      duration: 1,
      ease: 'Power2.easeOut',
      y: '40px',
      rotation: 4,
      opacity: 0,
      scrollTrigger: el,
    });
  });
}

function assetAnimation() {
  [...document.querySelectorAll('.js-animate-asset')].forEach((el) => {
    gsap.from(el, {
      duration: 1,
      ease: 'Power1.easeOut',
      transformOrigin: '50% 0%',
      scale: 1.1,
      scrollTrigger: {
        trigger: el,
        scrub: 0.5,
        end: '+=100%',
      },
    });
  });
}

export default function GlobalAnimations() {
  titleAnimation();
  blockTextAnimation();
  selfAnimation();
  assetAnimation();
}
