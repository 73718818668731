import Charming from 'charming';

export default class SplitText {
  constructor(element) {
    this.dom = {
      el: element,
    };
    this.prefixes = {
      words: 'word-',
      chars: 'char-',
    };
  }

  /**
   * Split into words
   * @param textArr {Array}
   * @returns {*[]}
   */
  splitWords(textArr = this.dom.el) {
    const that = this;
    Charming(textArr, {
      split(string) {
        return string.split(/(\s+)/);
      },
      setClassName(index) {
        return that.prefixes.words + index;
      },
    });

    return [...this.dom.el.querySelectorAll(`[class^="${this.prefixes.words}"]`)];
  }

  /**
   * Split into chars
   * @param textArr {Array}
   * @returns {*[]}
   */
  splitChars(textArr = this.dom.el) {
    const that = this;
    textArr.forEach((word) => {
      Charming(word, {
        setClassName(index) {
          return that.prefixes.chars + index;
        },
      });
    });

    return [...this.dom.el.querySelectorAll(`[class^="${this.prefixes.chars}"]`)];
  }

  /**
   * Splits into words than into chars
   * @param textArr {Array}
   * @returns {{words: Array, chars: Array}}
   */
  splitWordsThanChars(textArr = this.dom.el) {
    const words = this.splitWords(textArr);
    const chars = this.splitChars(words);

    return {
      words,
      chars,
    };
  }
}
