import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function checkHeroHeight() {
  const main = document.querySelector('main');
  const hero = document.querySelector('.l-hero');

  if (hero.offsetHeight > window.innerHeight) {
    main.classList.add('pt-0');
    hero.classList.add('has-scroll');
  } else {
    main.classList.remove('pt-0');
    hero.classList.remove('has-scroll');
  }
}

class AddDonationInfo {
  constructor(input, container) {
    this.dom = {
      input,
      container,
      inputs: [...container.querySelectorAll('input')],
    };

    input.addEventListener('change', () => {
      this.onInputChange();
      // check hero height on input change
      checkHeroHeight();
    });
  }

  onInputChange() {
    if (this.dom.input.checked) {
      this.dom.inputs.forEach((input) => {
        input.removeAttribute('disabled');
      });
      this.dom.container.classList.remove('d-none');
    } else {
      this.dom.inputs.forEach((input) => {
        input.setAttribute('disabled', true);
      });
      this.dom.container.classList.add('d-none');
    }
  }
}

function bgTransition() {
  gsap.timeline({
    scrollTrigger: {
      trigger: '.bg-transition',
      scrub: true,
    },
  })
    .from('.img-front', { y: '-50%' }, 0);
}

function onFormSubmit(e) {
  const form = e.target;
  const email = form.querySelector('input[name="email"]');
  const hiddenEmail = form.querySelector('input[name="hidden-email"]');

  if (email.value.trim() === '') {
    email.name = 'hidden-email';
    hiddenEmail.name = 'email';
  } else {
    email.name = 'email';
    hiddenEmail.name = 'hidden-email';
  }
}

export default function init() {
  const containers = document.querySelectorAll('.donation-details');
  const forms = [...document.querySelectorAll('form.l-donate')];

  containers.forEach((container) => {
    const input = container.closest('form')
      .querySelector('.js-chk-details');
    // eslint-disable-next-line no-new
    if (input) new AddDonationInfo(input, container);
  });

  bgTransition();

  // check hero height on resize
  window.addEventListener('resize', () => {
    checkHeroHeight();
  });

  forms.forEach((form) => {
    form.addEventListener('submit', onFormSubmit);
  });

  // check hero height on init
  checkHeroHeight();
}
