import confetti from 'canvas-confetti';

class Confetti {
  constructor(canvas) {
    this.canvas = canvas;
    this.count = 225;
    this.timeout = 6000;
    this.interval = null;
    this.defaults = {
      origin: { y: 1 },
      startVelocity: 50,
      ticks: 300,
    };
    this.confetti = confetti.create(canvas, {
      resize: true,
    });

    document.addEventListener('visibilitychange', this.toggleInterval.bind(this));
    this.triggerConfetti();
    this.toggleInterval();
  }

  toggleInterval() {
    if (document.visibilityState === 'visible') {
      this.interval = setInterval(this.triggerConfetti.bind(this), this.timeout);
    } else {
      clearInterval(this.interval);
    }
  }

  fireConfetti(particleRatio, opts) {
    this.confetti({
      ...this.defaults,
      ...opts,
      particleCount: Math.floor(this.count * particleRatio),
    });
  }

  triggerConfetti() {
    this.fireConfetti(0.25, {
      spread: 26,
      startVelocity: 70,
    });
    this.fireConfetti(0.2, {
      spread: 60,
    });
    this.fireConfetti(0.35, {
      spread: 100,
      decay: 0.91,
    });
    this.fireConfetti(0.1, {
      spread: 120,
      startVelocity: 45,
      decay: 0.92,
    });
    this.fireConfetti(0.1, {
      spread: 120,
      startVelocity: 6,
    });
  }
}

export default function init() {
  const canvasConfetti = document.getElementById('confetti');
  if (canvasConfetti) {
    // eslint-disable-next-line no-new
    new Confetti(canvasConfetti);
  }
}
